<template>
  <div
    class="rounded-2xl w-44 m-4 p-4 py-6 bg-white duration-500 cursor-pointer shadow-md hover:shadow-2xl hover:text-orange text-teal"
  >
    <div class="flex flex-col items-center justify-center">
      <span class="material-icons text-5xl"> {{ icon }} </span>
      <p class="text-center font-medium mb-4 mt-4 text-grey">
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
