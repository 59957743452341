<template>
  <button
    :disabled="animateButton"
    class="text-sm px-10 content items-center content-center"
    :class="[
      {
        [`shadow-md text-white font-bold py-2 sm:px-16 my-3 rounded-full mx-2 ${classTailwind}`]:
          mode === 'confirm' || !mode,
      },
      {
        [`bg-darkRed hover:bg-darkRed text-white font-bold py-2 sm:px-16 my-3 rounded-full mx-2 ${classTailwind}`]:
          mode === 'cancel',
      },
      {
        [`bg-orange hover:bg-orange text-white font-bold py-2 sm:px-16 my-3 rounded-full mx-2 ${classTailwind}`]:
          mode === 'pause',
      },
      { 'bg-teal hover:bg-teal-light': !backgroundColour },
      backgroundColour ? backgroundColour : '',
    ]"
  >
    <slot></slot>
    <svg
      v-if="animateButton"
      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      />
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    animateButton: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: false,
      default: null,
    },
    backgroundColour: {
      type: String,
      required: false,
    },
    classTailwind: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style scoped></style>
