<!-- @format -->

<template>
  <div class="flex flex-nowrap">
    <div class="flex justify-center py-20 flex-1">
      <h1
        class="text-teal border-2 rounded-3xl px-32 align-middle justify-center border-teal text-4xl font-bold p-6"
      >
        PRACTICE
      </h1>
    </div>
  </div>
  <div class="flex flex-wrap justify-center items-center">
    <div class="flex flex-wrap justify-center items-center w-10/12 pb-14">
      <base-item-card
        @click="selectPractice(practice)"
        v-for="practice in getPractices"
        :key="practice.id"
        :icon="'home'"
        :title="practice.practice_name"
      ></base-item-card>
    </div>
  </div>
  <div class="flex justify-center">
    <base-button @click="logoutUser" class="bg-orange">Logout</base-button>
  </div>
</template>

<script>
import baseItemCard from "@/components/ui/baseComponents/baseItemCard.vue";
import baseButton from "@/components/ui/baseComponents/BaseButton.vue";
export default {
  components: { baseItemCard, baseButton },
  methods: {
    async logoutUser() {
      const tokenPayload = localStorage.getItem("token");
      try {
        await this.$store.dispatch("auth/logout", tokenPayload);
        this.$router.push("/");
      } catch (err) {
        this.error = err.message || "Something went wrong, try later.";
      }
    },
    selectPractice(practice) {
      localStorage.setItem("practice", practice?.id);
      localStorage.setItem("practice_name", practice?.practice_name);
      localStorage.setItem("region", practice?.region);
      this.$router.push({ name: "homepage" });
    },
  },
  computed: {
    getPractices() {
      return this.$store.getters["auth/getPractices"];
    },
  },
};
</script>
